.polite-position {
    position: relative;
    min-height: 200px;
}

.polite-position-2 {
    position: absolute;
    top: 0;
    right: 0;
}
.error{
    border-bottom: 2px solid red;
}

.success{
    border-bottom: 2px solid green;
}